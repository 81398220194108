<template>
  <div class="class_test">
    <h3>{{ tableData[0].kecheng }}</h3>
    <div class="tj">
      <Fenxi :fenxi="tj"></Fenxi>
    </div>
    <div class="scores">
      <div
        v-for="(item, index) in tableData"
        :key="index"
        @click="handleEdit(item)"
      >
        <StuScore :title="item.studentName" :des="item.score"></StuScore>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import StuScore from "@/components/StuScore.vue";
import Fenxi from "@/components/fenxi.vue";
export default {
  name: "ClassTest",
  data() {
    return {
      tableData: [],
    };
  },
  components: {
    StuScore,
    Fenxi,
  },
  computed: {
    ...mapState(["loading"]),
    tj() {
      let total = this.tableData.length;
      let youxiu = 0,
        jige = 0,
        bujige = 0;
      for (let i in this.tableData) {
        if (this.tableData[i].score < 60) {
          bujige++;
        } else if (this.tableData[i].score >= 90) {
          youxiu++;
        } else {
          jige++;
        }
      }
      return { totle: total, jige, bujige, youxiu };
    },
  },
  methods: {
    tableRowClassName({ row }) {
      if (row.score < 70) {
        return "warning-row1";
      } else {
        return "";
      }
    },
    handleEdit(row) {
      console.log(row);
      this.$router.push({
        name: "JDScore",
        params: { jd: row.studentNumber },
        query: { jieduan: this.$route.query.jieduan, stuName: row.studentName },
      });
    },
  },
  async created() {
    this.$datas.meta = { ...this.$route.params };
    let res = await this.$datas.classScore;
    let tabledata = [];
    for (let i in res.data.msg.data) {
      tabledata.push({
        ...res.data.msg.data[i],
        id: i,
        kecheng: this.$route.query.kecheng,
      });
    }
    this.tableData = tabledata;
  },
};
</script>
<style lang="less" scoped>
.class_test {
  padding: 10px 15px;
}
.tj {
  display: flex;
  justify-content: space-between;
  color: #606266;
  padding: 10px 0;
}
.el-table .warning-row1 {
  background: oldlace;
}
.scores {
  overflow: hidden;
  > div {
    cursor: pointer;
  }
}
</style>
