<template>
  <div
    :class="{
      stu_score: true,
      youxiu: des >= 90,
      zhongdeng: des > 60 && des < 90,
      yiban: des <= 60,
    }"
    @mouseenter="enterAction"
    @mouseleave="leaveAction"
  >
    <h3>{{ title }}</h3>
    <div class="des">{{ des ? des : "--" }}</div>
    <div
      class="warn"
      v-if="warn"
      :style="{
        display: show ? 'block' : 'none',
      }"
    >
      <i class="iconfont icon-shangjiantou jiantou"></i>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "StuScore",
  data() {
    return {
      offsetX: 0,
      offsetY: 0,
      show: false,
    };
  },
  props: {
    title: {
      type: String,
      default: "标题",
    },
    des: {
      type: String,
      default: "0",
    },
    warn: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    enterAction() {
      if (this.warn) {
        //显示警告
        this.show = true;
      }
    },
    leaveAction() {
      this.show = false;
    },
  },
};
</script>
<style lang="less" scoped>
.stu_score {
  width: 100px;
  text-align: center;
  position: relative;
  background: red;
  padding: 5px 10px;
  margin: 20px;
  color: white;
  border-radius: 5px;
  float: left;
  h3 {
    font-size: 13px;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .des {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .warn {
    width: 200px;
    position: absolute;
    left: -50px;
    top: 70px;
    z-index: 10;
    background-color: #fff;
    padding: 10px;
    text-align: left;
    border-radius: 5px;
    color: #555;
    border: 1px solid #ccc;
    .jiantou {
      position: absolute;
      font-size: 25px;
      color: #ccc;
      left: 90px;
      top: -18px;
    }
  }
}
.youxiu {
  background-color: #f4f4f4;
  color: #555;
  border: 1px solid #555;
}
.zhongdeng {
  background-color: #409eff;
  border: 1px solid #409eff;
}
.yiban {
  background-color: #f56c6c;
  border: 1px solid #f56c6c;
}
</style>
