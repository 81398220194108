var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    stu_score: true,
    youxiu: _vm.des >= 90,
    zhongdeng: _vm.des > 60 && _vm.des < 90,
    yiban: _vm.des <= 60,
  },on:{"mouseenter":_vm.enterAction,"mouseleave":_vm.leaveAction}},[_c('h3',[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"des"},[_vm._v(_vm._s(_vm.des ? _vm.des : "--"))]),(_vm.warn)?_c('div',{staticClass:"warn",style:({
      display: _vm.show ? 'block' : 'none',
    })},[_c('i',{staticClass:"iconfont icon-shangjiantou jiantou"}),_vm._t("default")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }